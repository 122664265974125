import React, { useEffect, useRef, useState } from "react";
import ReactDOM from "react-dom";
import styled from "styled-components";


const StyledModalBody = styled.div`
        grid-auto-rows: max-content;
    `;

    const StyledModalHeader = styled.div`
        display: flex;
        justify-content: flex-end;
        font-size: 25px;
    `;

    const StyledModalOverlay = styled.div`
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: flex;
        z-index:100;
        justify-content: center;
        align-items: center;
        background-color: rgba(0, 0, 0, 0.5);
    `;
    const StyledModalWrapper = styled.div`
        width: calc(100vw - 100px);
        height: 600px;
    `;
  
    const StyledModal = styled.div`
        background: white;
        height:100%;
        width:70%;
        border-radius: 15px;
        margin:auto;
    `;

export default function Modal({show, onClose, children, title, html, isReference= true, index }) {
    const [isBrowser, setIsBrowser] = useState(false);
    const modalWrapperRef = React.useRef();
    const modalIndex = index;
    var isOpen = false;
    

    useEffect(() => {
        setIsBrowser(true);
        
    }, []);
    var tempDrop = undefined;
    const backDropHandler = (e) => {
        //console.log("hm", show,modalIndex, isOpen, modalWrapperRef)
        if(modalWrapperRef && isOpen ){
            if (modalWrapperRef && !modalWrapperRef?.current?.contains(e.target)) {
                //console.log("testt", show, modalWrapperRef)
                window.removeEventListener('click', backDropHandler);
                onClose(index);
            }
        }
        if(!isOpen){
            isOpen = true;
        }
        return false;
    }
    
    if(show && isBrowser){
        tempDrop = backDropHandler;
        window.addEventListener('click', tempDrop);
    }
    const handleCloseClick = (e) => {
        e.preventDefault();
        onClose(index);
        return () => window.removeEventListener('click', backDropHandler);
    };
    
    const modalContent = show ? (
        <>
        <StyledModalOverlay>
            <StyledModalWrapper ref={modalWrapperRef}>
                <StyledModal style={ isReference ? {padding: '0px'} : {padding: '15px'} }>
                    <StyledModalHeader style={ isReference ? {position: 'relative'} : ''}>
                        <a href="#" onClick={handleCloseClick} style={isReference ? {position: 'absolute', right: '15px', zIndex: '10'} : {}}>
                        x
                        </a>
                    </StyledModalHeader>
                    {title && <StyledModalTitle>{title}</StyledModalTitle>}
                    {html? (<StyledModalBody dangerouslySetInnerHTML={html} className="grid grid-cols-12" style={ isReference ? {height: '100%'} : {paddingTop: '10px'}}></StyledModalBody>) : (<StyledModalBody >{children}</StyledModalBody>)}
                </StyledModal>
            </StyledModalWrapper>
        </StyledModalOverlay>
        
        </>
    
    ) : null;

    if (isBrowser) {
        return ReactDOM.createPortal(
            modalContent,
            document.getElementById("modal-root")
    );
    } else {
        return null;
    }
}